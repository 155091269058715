const discoverButton = document.querySelector('.js-reviews-discover');
const hideButton = document.querySelector('.js-reviews-hide');
const disabledReviews = document.querySelectorAll('.js-review-disabled');

if (discoverButton && hideButton) {
  hideButton.style.display = 'none';

  hideButton.addEventListener('click', function (e) {
    this.style.display = 'none';
    discoverButton.style.display = 'inline';
    disabledReviews.forEach((review) => review.style.display = 'none');
  });


  discoverButton.addEventListener('click', function (e) {
    this.style.display = 'none';
    hideButton.style.display = 'inline';
    disabledReviews.forEach((review) => review.style.display = 'block');
  });

}
